import 'animate.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { modulActions, rightMenuActions } from '../../../actions';
import { eventConstants, modulConstants, rightmenuConstants } from '../../../constants';
import { ModulAbmeldungenClass, ModulClass, WPSModulAbmeldungenClass } from '../../../models/modul';
import { util } from '../../../services';
import { ErlaueterungForAuswahlAbmeldung, ModulNochNichtAnwendbar } from '../../HinweisTexte';
import { Spinner } from "../../spinner/Spinner";

const ModulList = ({ modul, handleChange, kannAbmelden }) => {
    if (!modul || !modul.modulabmeldungen || !kannAbmelden) return (<div />);
    else {
        return (
            <>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <input type="checkbox" name="modulabmeldungen.wp" style={{ marginRight: '15px' }} value={modul.modulabmeldungen.wp} checked={modul.modulabmeldungen.wp} onChange={e => handleChange(e)} disabled={!kannAbmelden.wp} /> Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <input type="checkbox" name="modulabmeldungen.bwl" style={{ marginRight: '15px' }} value={modul.modulabmeldungen.bwl} checked={modul.modulabmeldungen.bwl} onChange={e => handleChange(e)} disabled={!kannAbmelden.bwl} /> Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="checkbox" name="modulabmeldungen.wr" style={{ marginRight: '15px' }} value={modul.modulabmeldungen.wr} checked={modul.modulabmeldungen.wr} onChange={e => handleChange(e)} disabled={!kannAbmelden.wr} /> Wirtschaftsrecht
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="checkbox" name="modulabmeldungen.st" style={{ marginRight: '15px' }} value={modul.modulabmeldungen.st} checked={modul.modulabmeldungen.st} onChange={e => handleChange(e)} disabled={!kannAbmelden.st} /> Steuerrecht
                    </div>
                </div>
            </>
        );
    }
}

const AuswahlModul = ({ aktuelSemester, handleSubmit, modul, setModul, kannAbmelden, modulLoading, showSpinner, setShowSpinner }) => {
    const dispatch = useDispatch();

    if (modulLoading) {
        setShowSpinner(true)
    } else {
        setShowSpinner(false)
    }

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("modulabmeldungen") > -1) {
            setModul({
                ...modul,
                modulabmeldungen: {
                    ...modul.modulabmeldungen,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setModul({ ...modul, [e.target.name]: v });
        }
    };

    if (!modul || !kannAbmelden) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
                    <Spinner show={showSpinner} />
                    <h2 style={{ paddingTop: '25px' }}>Auswahl Modul(e)</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Meine Anmeldung zur Modulprüfung</span>

                            <ModulList
                                modul={modul}
                                handleChange={handleChange}
                                kannAbmelden={kannAbmelden}
                            />

                            <span className="formLabel">möchte ich hiermit verbindlich zurücknehmen.</span>

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                                </div>
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                            </div>

                            <br />


                        </div>
                    </div>

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const Zusammenfassung = ({ aktuelSemester, modul, modulBefore, kannAbmelden, showSpinner, setShowSpinner }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const d = useSelector((state) => state.modulList.done);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.modulList.error);
    const [error, setError] = useState(e);

    const WP = () => {
        return (
            <li>
                <p>Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht</p>
            </li>
        );
    }

    const BWL = () => {
        return (
            <li>
                <p>Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre</p>
            </li>
        );
    }

    const WR = () => {
        return (
            <li>
                <p>Wirtschaftsrecht</p>
            </li>
        );
    }

    const ST = () => {
        return (
            <li>
                <p>Steuerrecht</p>
            </li>
        );
    }

    useEffect(() => {
        if (done) {
            if (error) {
                setShowSpinner(false);
                dispatch({ type: modulConstants.GET_FAILURE_DONE });
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.error("Sie haben sich nicht erfolgreich abgemeldet.");
            } else {
                setShowSpinner(false);
                dispatch({ type: modulConstants.GET_DONE });
                dispatch(modulActions.getModulList());
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.success("Sie haben sich erfolgreich abgemeldet.");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

    const handleBack = (e) => {
        e.preventDefault();
        dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var abm = new WPSModulAbmeldungenClass();
        abm.art = eventConstants.MODULPRUEFUNG_ABMELDUNG;
        abm.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
        abm.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
        abm.wp = modul.modulabmeldungen.wp;
        abm.wr = modul.modulabmeldungen.wr;
        abm.bwl = modul.modulabmeldungen.bwl;
        abm.st = modul.modulabmeldungen.st;
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return false
            else return value;
        }
        var modulAbmeldungInfos = JSON.parse(JSON.stringify(abm, replacer));

        setShowSpinner(true);
        dispatch(modulActions.saveEventInfos(modul, modulBefore, modulAbmeldungInfos));
        // toast.success("Ihr Zulassungsantrag wurde abgeschickt");
        // history.push("/examensportal/start");

    };


    if (!modul || !modul.modulabmeldungen || !kannAbmelden) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
                    <Spinner show={showSpinner} />
                    <h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>

                    <span className="formLabel">Ich nehme meine Anmeldung(en) zu der/den Modulprüfung(en) verbindlich zurück:</span>
                    <ul>
                        {modul.modulabmeldungen.wp && kannAbmelden.wp && <WP />}
                        {modul.modulabmeldungen.bwl && kannAbmelden.bwl && <BWL />}
                        {modul.modulabmeldungen.wr && kannAbmelden.wr && <WR />}
                        {modul.modulabmeldungen.st && kannAbmelden.st && <ST />}
                    </ul>
                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
                        <input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const MenuRight = ({ tab, handleSubmit }) => {
    const dispatch = useDispatch();
    return (
        <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide" >
            <p className="menu_right_header">Rücknahme der Anmeldung zu einer Modulprüfung</p>
            <ul id="menu_right">
                <li style={tab === 1 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))} style={tab === 1 ? { background: '#a0bcd1' } : {}}>Auswahl Modul(e)</a>
                </li>
                <li style={tab === 2 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={(e) => { handleSubmit(e) ? dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG)) : dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)) }} style={tab === 2 ? { background: '#a0bcd1' } : {}}>Zusammenfassung und Abschicken</a>
                </li>
            </ul>
            <br /><br />
        </div>
    );
}

export const AbmeldungForm = () => {
    const dispatch = useDispatch();
    const tab = useSelector(state => state.rightMenu);
    const ml = new ModulClass();
    const [modul, setModul] = useState(ml);
    const mlb = useSelector(state => state.modulList.modulListBefore);
    const [modulBefore, setModulBefore] = useState(mlb)
    const modulLoading = useSelector((state) => state.modulList.loading);
    const zulassungLoading = useSelector((state) => state.zulassung.loading);
    const [showSpinner, setShowSpinner] = useState(true);
    const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
    const [zulassungsblock, setZulassungsblock] = useState(zb);


    const z = useSelector(state => state.zulassung.zulassung);
    const [zulassung, setZulassung] = useState(z);
    var kannAbmelden; var aktuelSemester; var semester;

    if (modul && modulBefore) {
        aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);

        if (modulBefore.modulanmeldungen) {
            kannAbmelden = new ModulAbmeldungenClass();
            kannAbmelden.wp = modulBefore.modulabmeldungen.wp;
            kannAbmelden.bwl = modulBefore.modulabmeldungen.bwl;
            kannAbmelden.wr = modulBefore.modulabmeldungen.wr;
            kannAbmelden.st = modulBefore.modulanmeldungen.st;
            kannAbmelden.wpVorzeitig = modulBefore.modulabmeldungen.wpVorgezogen;
            kannAbmelden.bwlVorzeitig = modulBefore.modulabmeldungen.bwlVorgezogen;
            kannAbmelden.wrVorzeitig = modulBefore.modulabmeldungen.wrVorgezogen;
            kannAbmelden.stVorzeitig = modulBefore.modulabmeldungen.stVorgezogen;
        }

        if (aktuelSemester !== "SEM") {
            semester = parseInt(aktuelSemester.split('/')[0], 10);
        } else {
            semester = 0;
        }
    }

    useEffect(() => {
        if (!modulLoading && !zulassungLoading) {
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [modulLoading, zulassungLoading]);

    useEffect(() => {
        setModulBefore(mlb);
    }, [mlb]);

    useEffect(() => {
        setZulassungsblock(zb);
    }, [zb]);

    const ErlaueterungForZusammenfassung = () => {
        return (
            <div className="hinweisText" style={{ marginBottom: '20px' }}>
                <p>
                    Ihre Abmeldung(en), die Rücknahme der Anmeldung zu einer oder mehreren Modulprüfungen, wird hier zusammengefasst.
                    Bitte prüfen Sie Ihre Angaben, bevor Sie Ihre Abmeldung verbindlich absenden.
                </p>
                <p>
                    Ihre Abmeldung wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.
                </p>
            </div>
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!kannAbmelden.wp &&
            !kannAbmelden.wr &&
            !kannAbmelden.bwl &&
            !kannAbmelden.st) {
            toast.error("Sie können sich für keine Modulprüfung abmelden.")
            return false;
        }

        if (!modul.modulabmeldungen.wp &&
            !modul.modulabmeldungen.wr &&
            !modul.modulabmeldungen.bwl &&
            !modul.modulabmeldungen.st) {
            toast.error("Sie haben keine Modulprüfung ausgewählt.")
            return false;
        }

        if (modul.modulabmeldungen.wp ||
            modul.modulabmeldungen.wr ||
            modul.modulabmeldungen.bwl ||
            modul.modulabmeldungen.st) {
            const iban = modulBefore.kontaktdaten.iban;
            const message = 'Ist Ihre Bankverbindung (IBAN <b>' + iban + '</b>) für eine mögliche Gebührenerstattung noch aktuell? <br/> Bitte aktualisieren Sie ggf. nach dem Absenden Ihre Angaben unter <br /> <b>Meine Daten &#x2192 Kontaktdaten ändern</b>';
            Swal.fire({
                html: message,
                width: 800,
                confirmButtonColor: '#4C82A8',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                }
            })
            toast.info("Die Daten wurden zwischengespeichert.");
            // dispatch(modulActions.saveTempModul(modul));
            dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
            return true;
        }
    };

    if (!modul)
        return (
            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                <ErlaueterungForAuswahlAbmeldung />
                <ModulNochNichtAnwendbar />
            </div>
        );
    else if (modulBefore && modulBefore.zwischegespeichert === 2) {
        return (
            <>
                <form>
                    <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">

                        {tab === 1 && <ErlaueterungForAuswahlAbmeldung />}
                        {tab === 2 && <ErlaueterungForZusammenfassung />}

                        {tab === 1 && (<AuswahlModul
                            aktuelSemester={aktuelSemester}
                            modul={modul}
                            setModul={setModul}
                            kannAbmelden={kannAbmelden}
                            modulLoading={modulLoading}
                            showSpinner={showSpinner}
                            setShowSpinner={setShowSpinner}
                            handleSubmit={handleSubmit}
                        />)}
                        {tab === 2 && (<Zusammenfassung
                            aktuelSemester={aktuelSemester}
                            modul={modul}
                            modulBefore={modulBefore}
                            kannAbmelden={kannAbmelden}
                            showSpinner={showSpinner}
                            setShowSpinner={setShowSpinner}
                        />)}
                    </div>
                </form>
                {tab === 1 && (<MenuRight
                    tab={tab}
                    handleSubmit={handleSubmit}
                />)}
                {tab === 2 && (<MenuRight
                    tab={tab}
                    handleSubmit={handleSubmit}
                />)}
            </>
        );
    } else {
        return (
            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                <ErlaueterungForAuswahlAbmeldung />
                <ModulNochNichtAnwendbar />
            </div>
        );
    }
}