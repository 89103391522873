import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { modulActions, rightMenuActions } from '../../../actions';
import { eventConstants, modulConstants, rightmenuConstants } from '../../../constants';
import { ModulClass, ModulVerhindertClass, WPSModulVerhindertClass } from '../../../models/modul';
import { util } from '../../../services';
import { GrundText, ModulNochNichtAnwendbar } from '../../HinweisTexte';
import FileUpload from '../../fileupload/fileupload';
import FileUploadList from '../../fileupload/fileuploadlist';
import { Spinner } from "../../spinner/Spinner";




const Grund = ({
    grund,
    setGrund,
    zeugnis,
    setZeugnis,
    aktuelSemester,
    semester,
    modul,
    setModul,
    schonVerhindert,
    modulLoading,
    showSpinner,
    setShowSpinner,
    handleSubmit
}) => {

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("modulverhindert") > -1) {
            setModul({
                ...modul,
                modulverhindert: {
                    ...modul.modulverhindert,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setModul({ ...modul, [e.target.name]: v });
        }
    };

    if (!modul || !modul.modulverhindert || !schonVerhindert) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

                    <h2 style={{ paddingTop: '25px' }}>Abwesenheitsgrund</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich kann an der</span>
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.wp" style={{ marginRight: '15px' }} value={modul.modulverhindert.wp} checked={modul.modulverhindert.wp} onChange={e => handleChange(e)} disabled={!schonVerhindert.wp} /> schriftlichen Prüfung in Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.bwl" style={{ marginRight: '15px' }} value={modul.modulverhindert.bwl} checked={modul.modulverhindert.bwl} onChange={e => handleChange(e)} disabled={!schonVerhindert.bwl} /> schriftlichen Prüfung in Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.wr" style={{ marginRight: '15px' }} value={modul.modulverhindert.wr} checked={modul.modulverhindert.wr} onChange={e => handleChange(e)} disabled={!schonVerhindert.wr} /> schriftlichen Prüfung in Wirtschaftsrecht
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.st" style={{ marginRight: '15px' }} value={modul.modulverhindert.st} checked={modul.modulverhindert.st} onChange={e => handleChange(e)} disabled={!schonVerhindert.st} /> schriftlichen Prüfung in Steuerrecht
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">&nbsp;</div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.wpmuendlich" style={{ marginRight: '15px' }} value={modul.modulverhindert.wpmuendlich} checked={modul.modulverhindert.wpmuendlich} onChange={e => handleChange(e)} disabled={!schonVerhindert.wpmuendlich} /> mündlichen Prüfung in Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.bwlmuendlich" style={{ marginRight: '15px' }} value={modul.modulverhindert.bwlmuendlich} checked={modul.modulverhindert.bwlmuendlich} onChange={e => handleChange(e)} disabled={!schonVerhindert.bwlmuendlich} /> mündlichen Prüfung in Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.wrmuendlich" style={{ marginRight: '15px' }} value={modul.modulverhindert.wrmuendlich} checked={modul.modulverhindert.wrmuendlich} onChange={e => handleChange(e)} disabled={!schonVerhindert.wrmuendlich} /> mündlichen Prüfung in Wirtschaftsrecht
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.stmuendlich" style={{ marginRight: '15px' }} value={modul.modulverhindert.stmuendlich} checked={modul.modulverhindert.stmuendlich} onChange={e => handleChange(e)} disabled={!schonVerhindert.stmuendlich} /> mündlichen Prüfung in Steuerrecht
                                </div>
                            </div>
                            <span className="formLabel">nicht teilnehmen.</span>

                            <div className="row">
                                <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Ich bin erkrankt. Ein amtsärztliches Zeugnis füge ich bei.</span>
                                    <FileUpload files={zeugnis} setFiles={setZeugnis} />
                                </div>
                            </div>
                            <FileUploadList files={zeugnis} setFiles={setZeugnis} label={'Zeugnis'} />

                            <div className="row">
                                <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Hierfür gibt es einen sonstigen Grund. Eine Begründung und ggf. Nachweise füge ich bei.</span>
                                    <FileUpload files={grund} setFiles={setGrund} />
                                </div>
                            </div>
                            <FileUploadList files={grund} setFiles={setGrund} label={'Sonstiger Grund'} />

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                                </div>
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                            </div>

                            <br />


                        </div>
                    </div>

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const GrundEignung = ({
    grund,
    setGrund,
    zeugnis,
    setZeugnis,
    aktuelSemester,
    semester,
    modul,
    setModul,
    schonVerhindert,
    modulLoading,
    showSpinner,
    setShowSpinner,
    handleSubmit
}) => {

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("modulverhindert") > -1) {
            setModul({
                ...modul,
                modulverhindert: {
                    ...modul.modulverhindert,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setModul({ ...modul, [e.target.name]: v });
        }
    };

    if (!modul || !modul.modulverhindert || !schonVerhindert) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

                    <h2 style={{ paddingTop: '25px' }}>Abwesenheitsgrund</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich kann an der</span>
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.wp" style={{ marginRight: '15px' }} value={modul.modulverhindert.awr} checked={modul.modulverhindert.awr} onChange={e => handleChange(e)} disabled={!schonVerhindert.awr} /> Aufsichtsarbeit Wirtschaftsrecht
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.bwl" style={{ marginRight: '15px' }} value={modul.modulverhindert.ast} checked={modul.modulverhindert.ast} onChange={e => handleChange(e)} disabled={!schonVerhindert.ast} /> Aufsichtsarbeit Steuerrecht I
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <input type="checkbox" name="modulverhindert.wr" style={{ marginRight: '15px' }} value={modul.modulverhindert.mp} checked={modul.modulverhindert.mp} onChange={e => handleChange(e)} disabled={!schonVerhindert.mp} /> mündlichen Prüfung
                                </div>
                            </div>
                            <span className="formLabel">nicht teilnehmen.</span>

                            <div className="row">
                                <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Ich bin erkrankt. Ein amtsärztliches Zeugnis füge ich bei.</span>
                                    <FileUpload files={zeugnis} setFiles={setZeugnis} />
                                </div>
                            </div>
                            <FileUploadList files={zeugnis} setFiles={setZeugnis} label={'Zeugnis'} />

                            <div className="row">
                                <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Hierfür gibt es einen sonstigen Grund. Eine Begründung und ggf. Nachweise füge ich bei.</span>
                                    <FileUpload files={grund} setFiles={setGrund} />
                                </div>
                            </div>
                            <FileUploadList files={grund} setFiles={setGrund} label={'Begründung / ggf. Nachweis'} />

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                                </div>
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                            </div>

                            <br />


                        </div>
                    </div>

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const Zusammenfassung = ({
    aktuelSemester,
    semester,
    modul,
    modulBefore,
    schonVerhindert,
    grund,
    zeugnis,
    showSpinner,
    setShowSpinner
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const d = useSelector((state) => state.modulList.done);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.modulList.error);
    const [error, setError] = useState(e);

    const WP = () => {
        return (
            <li>
                <p>schriftliche Prüfung in Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht</p>
            </li>
        );
    }

    const BWL = () => {
        return (
            <li>
                <p>schriftliche Prüfung in Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre</p>
            </li>
        );
    }

    const WR = () => {
        return (
            <li>
                <p>schriftliche Prüfung in Wirtschaftsrecht</p>
            </li>
        );
    }

    const ST = () => {
        return (
            <li>
                <p>schriftliche Prüfung in Steuerrecht</p>
            </li>
        );
    }

    const MWP = () => {
        return (
            <li>
                <p>mündliche Prüfung in Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht</p>
            </li>
        );
    }

    const MBWL = () => {
        return (
            <li>
                <p>mündliche Prüfung in Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre</p>
            </li>
        );
    }

    const MWR = () => {
        return (
            <li>
                <p>mündliche Prüfung in Wirtschaftsrecht</p>
            </li>
        );
    }

    const MST = () => {
        return (
            <li>
                <p>mündliche Prüfung in Steuerrecht</p>
            </li>
        );
    }

    useEffect(() => {
        if (done) {
            if (error) {
                setShowSpinner(false);
                dispatch({ type: modulConstants.GET_FAILURE_DONE });
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.error("Der Triftige Grunde wurde nicht erfolgreich abgesendet.");
            } else {
                setShowSpinner(false);
                dispatch({ type: modulConstants.GET_DONE });
                dispatch(modulActions.getModulList());
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.success("Der Triftige Grunde wurde erfolgreich abgesendet.");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

    const handleBack = (e) => {
        e.preventDefault();
        //dispatch(modulActions.saveTempModul(modul));
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var mv = new WPSModulVerhindertClass();
        mv.art = eventConstants.TRIFFTIGERGRUND;
        mv.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
        mv.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
        mv.wp = modul.modulverhindert.wp;
        mv.wr = modul.modulverhindert.wr;
        mv.bwl = modul.modulverhindert.bwl;
        mv.st = modul.modulverhindert.st;
        mv.wpmuendlich = modul.modulverhindert.wpmuendlich;
        mv.wrmuendlich = modul.modulverhindert.wrmuendlich;
        mv.bwlmuendlich = modul.modulverhindert.bwlmuendlich;
        mv.stmuendlich = modul.modulverhindert.stmuendlich;
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return false
            else return value;
        }
        var modulVerhindertInfos = JSON.parse(JSON.stringify(mv, replacer));

        setShowSpinner(true);
        dispatch(modulActions.saveVerhindertInfos(modul, modulBefore, modulVerhindertInfos, zeugnis, grund));
        // toast.success("Ihr Zulassungsantrag wurde abgeschickt");
        // history.push("/examensportal/start");

    };

    if (!modul || !modul.modulverhindert || !modulBefore) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

                    <h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich kann an der/den Modulprüfung(en)</span>

                            <ul>
                                {modul.modulverhindert.wp && schonVerhindert.wp && <WP />}
                                {modul.modulverhindert.bwl && schonVerhindert.bwl && <BWL />}
                                {modul.modulverhindert.wr && schonVerhindert.wr && <WR />}
                                {modul.modulverhindert.st && schonVerhindert.st && <ST />}
                                {modul.modulverhindert.wpmuendlich && schonVerhindert.wpmuendlich && <MWP />}
                                {modul.modulverhindert.bwlmuendlich && schonVerhindert.bwlmuendlich && <MBWL />}
                                {modul.modulverhindert.wrmuendlich && schonVerhindert.wrmuendlich && <MWR />}
                                {modul.modulverhindert.stmuendlich && schonVerhindert.stmuendlich && <MST />}
                            </ul>

                            <span className="formLabel">nicht teilnehmen.</span>

                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <b>Ich bin erkrankt. Ein amtsärztliches Zeugnis füge ich bei:</b>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    {zeugnis
                                        ? zeugnis.map((f) => {
                                            return <div>{f.name}</div>;
                                        })
                                        : null}
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <b>Hierfür gibt es einen sonstigen Grund. Eine Begründung und ggf. Nachweise füge ich bei:</b>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    {grund
                                        ? grund.map((f) => {
                                            return <div>{f.name}</div>;
                                        })
                                        : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                                </div>
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                            </div>

                            <br />


                        </div>
                    </div>

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
                        <input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const MenuRight = ({ tab, handleSubmit }) => {
    const dispatch = useDispatch();
    return (
        <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide" >
            <p className="menu_right_header">Rücknahme der Anmeldung zu einer Modulprüfung</p>
            <ul id="menu_right">
                <li style={tab === 1 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))} style={tab === 1 ? { background: '#a0bcd1' } : {}}>Auswahl Modul(e)</a>
                </li>
                <li style={tab === 2 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={(e) => { handleSubmit(e) ? dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG)) : dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)) }} style={tab === 2 ? { background: '#a0bcd1' } : {}}>Zusammenfassung und Abschicken</a>
                </li>
            </ul>
            <br /><br />
        </div>
    );
}

export const GrundForm = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication.user);
    const tab = useSelector(state => state.rightMenu);
    const ml = new ModulClass();
    const [modul, setModul] = useState(ml);
    const mlb = useSelector(state => state.modulList.modulListBefore);
    const [modulBefore, setModulBefore] = useState(mlb);
    const modulLoading = useSelector((state) => state.modulList.loading);
    const zulassungLoading = useSelector((state) => state.zulassung.loading);
    const [showSpinner, setShowSpinner] = useState(true);
    const [zeugnis, setZeugnis] = useState([]);
    const [grund, setGrund] = useState([]);
    const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
    const [zulassungsblock, setZulassungsblock] = useState(zb);

    const z = useSelector(state => state.zulassung.zulassung);
    const [zulassung, setZulassung] = useState(z);

    var schonVerhindert; var aktuelSemester; var semester;
    if (modul && modulBefore) {
        aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);

        if (modulBefore.modulverhindert) {
            schonVerhindert = new ModulVerhindertClass();
            schonVerhindert.wp = modulBefore.modulverhindert.wp;
            schonVerhindert.bwl = modulBefore.modulverhindert.bwl;
            schonVerhindert.wr = modulBefore.modulverhindert.wr;
            schonVerhindert.st = modulBefore.modulverhindert.st;
            schonVerhindert.wpmuendlich = modulBefore.modulverhindert.wpmuendlich;
            schonVerhindert.bwlmuendlich = modulBefore.modulverhindert.bwlmuendlich;
            schonVerhindert.wrmuendlich = modulBefore.modulverhindert.wrmuendlich;
            schonVerhindert.stmuendlich = modulBefore.modulverhindert.stmuendlich;
        }

        if (aktuelSemester !== "SEM") {
            semester = parseInt(aktuelSemester.split('/')[0], 10);
        } else {
            semester = 0;
        }
    }

    console.log(zulassungsblock.zulassungsblock);

    useEffect(() => {
        if (!modulLoading && !zulassungLoading) {
            //console.log("Modul geladen")
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [modulLoading, zulassungLoading]);

    useEffect(() => {
        setModulBefore(mlb);
    }, [mlb]);

    useEffect(() => {
        setZulassungsblock(zb);
    }, [zb]);


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!modul.modulverhindert.wp &&
            !modul.modulverhindert.wr &&
            !modul.modulverhindert.bwl &&
            !modul.modulverhindert.st &&
            !modul.modulverhindert.wpmuendlich &&
            !modul.modulverhindert.wrmuendlich &&
            !modul.modulverhindert.bwlmuendlich &&
            !modul.modulverhindert.stmuendlich
        ) {
            toast.error("Sie können momentan von keiner Modulprüfung zurücktreten.")
            return false;
        }

        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
        return true;
    };


    const ZusammenfassungText = () => {
        return (
            <div className="hinweisText" style={{ marginBottom: '20px' }}>
                <p>
                    Ihre Mitteilung eines triftigen Grundes wird hier zusammengefasst.
                    Bitte prüfen Sie Ihre Angaben, bevor Sie Ihre Mitteilung verbindlich absenden.
                </p>
                <p>
                    Ihre Mitteilung wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.
                </p>
            </div>
        );
    }

    if (!modul) {
        return (
            <form>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <GrundText />

                    <ModulNochNichtAnwendbar />
                </div>
            </form>
        );
    }
    else {
        if (user && user.art && user.art === 'WP') {
            if (modulBefore && modulBefore.zwischegespeichert === 2) {
                return (
                    <>
                        <form>
                            <Spinner show={showSpinner} />
                            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                {tab === 1 && <GrundText />}
                                {tab === 2 && <ZusammenfassungText />}

                                {tab === 1 &&
                                    <Grund
                                        grund={grund}
                                        setGrund={setGrund}
                                        zeugnis={zeugnis}
                                        setZeugnis={setZeugnis}
                                        aktuelSemester={aktuelSemester}
                                        semester={semester}
                                        modul={modul}
                                        setModul={setModul}
                                        schonVerhindert={schonVerhindert}
                                        modulLoading={modulLoading}
                                        showSpinner={showSpinner}
                                        setShowSpinner={setShowSpinner}
                                        handleSubmit={handleSubmit}
                                    />}
                                {tab === 2 && <Zusammenfassung
                                    aktuelSemester={aktuelSemester}
                                    semester={semester}
                                    modul={modul}
                                    modulBefore={modulBefore}
                                    schonVerhindert={schonVerhindert}
                                    grund={grund}
                                    zeugnis={zeugnis}
                                    showSpinner={showSpinner}
                                    setShowSpinner={setShowSpinner}
                                    user={user}
                                />}
                            </div>
                        </form>
                        {tab === 1 && (<MenuRight
                            tab={tab}
                            handleSubmit={handleSubmit}
                        />)}
                        {tab === 2 && (<MenuRight
                            tab={tab}
                            handleSubmit={handleSubmit}
                        />)}
                    </>
                );
            } else {
                return (
                    <form>
                        <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            <GrundText />

                            <ModulNochNichtAnwendbar />
                        </div>
                    </form>
                );
            }
        }
    }
}