import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { modulActions, rightMenuActions } from '../../../actions';
import { eventConstants, modulConstants, rightmenuConstants } from '../../../constants';
import { ModulAnrechnungenClass, ModulClass, WPSModulAnrechnungenClass } from '../../../models/modul';
import { util } from '../../../services';
import { AnrechnenText, ModulNochNichtAnwendbar } from '../../HinweisTexte';
import FileUpload from '../../fileupload/fileupload';
import FileUploadList from '../../fileupload/fileuploadlist';
import { Spinner } from "../../spinner/Spinner";


export const Anrechnen = ({
	pruefungsbescheinigung,
	setPruefungsbescheinigung,
	masterzeugnis,
	setMasterzeugnis,
	aktuelSemester,
	semester,
	modul,
	setModul,
	kannAnrechnen,
	modulLoading,
	showSpinner,
	setShowSpinner,
	handleSubmit
}) => {

	const handleChange = (e) => {
		let v = false;
		if (e.target.type === "checkbox" || e.target.type === "radio") {
			if (e.target.value === "false" || e.target.value === false) {
				v = true;
			}
		} else {
			v = e.target.value;
		}

		if (e.target.name.indexOf("modulanrechnungen") > -1) {
			setModul({
				...modul,
				modulanrechnungen: {
					...modul.modulanrechnungen,
					[e.target.name.split(".")[1]]: v,
				},
			});
		} else {
			setModul({ ...modul, [e.target.name]: v });
		}
	};

	if (!modul || !modul.modulanrechnungen || !kannAnrechnen) return (<div />);
	else {
		return (
			<>
				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

					<h2 style={{ paddingTop: '25px' }}>Auswahl Modul(e)</h2>

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Ich möchte die weitere Prüfung in verkürzter Form ablegen. Ich kann die Modulprüfung</span>
							{/*<div className="row">
								<div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
									<input type="checkbox" name="modulanrechnungen.wp" style={{ marginRight: '15px' }} value={modul.modulanrechnungen.wp} checked={modul.modulanrechnungen.wp} onChange={e => handleChange(e)} disabled={!kannAnrechnen.wp} /> Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht
								</div>
							</div> */}
							<div className="row">
								<div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
									<input type="checkbox" name="modulanrechnungen.bwl" style={{ marginRight: '15px' }} value={modul.modulanrechnungen.bwl} checked={modul.modulanrechnungen.bwl} onChange={e => handleChange(e)} disabled={!kannAnrechnen.bwl} /> Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre
								</div>
							</div>
							<div className="row">
								<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
									<input type="checkbox" name="modulanrechnungen.wr" style={{ marginRight: '15px' }} value={modul.modulanrechnungen.wr} checked={modul.modulanrechnungen.wr} onChange={e => handleChange(e)} disabled={!kannAnrechnen.wr} /> Wirtschaftsrecht
								</div>
							</div>
							<div className="row">
								<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
									<input type="checkbox" name="modulanrechnungen.st" style={{ marginRight: '15px' }} value={modul.modulanrechnungen.st} checked={modul.modulanrechnungen.st} onChange={e => handleChange(e)} disabled={!kannAnrechnen.st} /> Steuerrecht
								</div>
							</div>
							<span className="formLabel">durch prüfungsäquivalente Leistungen ersetzen.</span>
						</div>
					</div>

					<div className="row">
						<div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Bei Auswahl "StR" Urkunde Steuerberater oder Prüfungsbescheinigung Steuerberater</span>
							<FileUpload files={pruefungsbescheinigung} setFiles={setPruefungsbescheinigung} />
						</div>
					</div>
					<FileUploadList files={pruefungsbescheinigung} setFiles={setPruefungsbescheinigung} label={'Pruefungsbescheinigung'} />

					<div className="row">
						<div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Bei Auswahl "BWL" und/oder "WR" 8a-Masterzeugnis und -urkunde oder 13b-Zeugnis und -Urkunde und Transcript of Records; Bescheinigung über die Prüfungsleistungen; 13b-Zertifikat</span>
							<FileUpload files={masterzeugnis} setFiles={setMasterzeugnis} />
						</div>
					</div>
					<FileUploadList files={masterzeugnis} setFiles={setMasterzeugnis} label={'Masterzeugnis'} />
					{/* 
					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Bei Auswahl "StR" Urkunde Steuerberater oder Prüfungsbescheinigung Steuerberater</span>
							<input type="file" multiple name="pruefungsbescheinigung" className="nonPrintable" onChange={e => handleAgbescheinigungen(e)} />
							<span className="formLabel">Bei Auswahl "BWL" und/oder "WR" 8a-Masterzeugnis und -urkunde oder 13b-Zeugnis und -Urkunde und Transcript of Records; Bescheinigung über die Prüfungsleistungen; 13b-Zertifikat</span>
							<input type="file" multiple name="masterzeugnis" className="nonPrintable" onChange={e => handleZeugnisse(e)} />
						</div>
					</div> */}

					<div className="row">
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
						</div>
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
					</div>

					<br />

				</div>

				<div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
					</div>
				</div>
			</>
		);
	}
}

export const Zusammenfassung = ({
	aktuelSemester,
	semester,
	modul,
	modulBefore,
	kannAnrechnen,
	masterzeugnis,
	pruefungsbescheinigung,
	showSpinner,
	setShowSpinner
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const d = useSelector((state) => state.modulList.done);
	const [done, setDone] = useState(d)
	const e = useSelector((state) => state.modulList.error);
	const [error, setError] = useState(e);

	const WP = () => {
		return (
			<li>
				<p>Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht</p>
			</li>
		);
	}

	const BWL = () => {
		return (
			<li>
				<p>Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre</p>
			</li>
		);
	}


	const WR = () => {
		return (
			<li>
				<p>Wirtschaftsrecht</p>
			</li>
		);
	}

	const ST = () => {
		return (
			<li>
				<p>Steuerrecht</p>
			</li>
		);
	}

	useEffect(() => {
		if (done) {
			if (error) {
				setShowSpinner(false);
				dispatch({ type: modulConstants.GET_FAILURE_DONE });
				dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
				toast.error("Die Anrechnung wurde nicht erfolgreich abgesendet.");
			} else {
				setShowSpinner(false);
				dispatch({ type: modulConstants.GET_DONE });
				dispatch(modulActions.getModulList());
				dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
				toast.success("Die Anrechnung wurde erfolgreich abgesendet.");
				history.push("/examensportal/start");
			}
		}
	}, [d, history]);

	const handleBack = (e) => {
		e.preventDefault();
		//dispatch(modulActions.saveTempModul(modul));
		toast.info("Die Daten wurden zwischengespeichert.");
		dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		var anr = new WPSModulAnrechnungenClass();
		anr.art = eventConstants.MODULPRUEFUNG_ANRECHNUNG;
		anr.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
		anr.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
		anr.wp = modul.modulanrechnungen.wp;
		anr.wr = modul.modulanrechnungen.wr;
		anr.bwl = modul.modulanrechnungen.bwl;
		anr.st = modul.modulanrechnungen.st;
		const replacer = (key, value) => {
			if (typeof value === 'undefined')
				return false
			else return value;
		}
		var modulAnrechnungInfos = JSON.parse(JSON.stringify(anr, replacer));

		setShowSpinner(true);
		dispatch(modulActions.saveAnrechnungInfos(modul, modulBefore, modulAnrechnungInfos, masterzeugnis, pruefungsbescheinigung));
		// toast.success("Ihr Zulassungsantrag wurde abgeschickt");
		// history.push("/examensportal/start");

	};

	if (!modul || !modul.modulanrechnungen || !kannAnrechnen) return (<div />);
	else {
		return (
			<>
				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
					<Spinner show={showSpinner} />
					<h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>
					<span className="formLabel">Ich möchte die weitere Prüfung in verkürzter Form ablegen. Ich kann die Modulprüfung</span>
					<ul>
						{modul.modulanrechnungen.wp && false && <WP />} {/* Kann man nicht Anrechnen */}
						{modul.modulanrechnungen.bwl && kannAnrechnen.bwl && <BWL />}
						{modul.modulanrechnungen.wr && kannAnrechnen.wr && <WR />}
						{modul.modulanrechnungen.st && kannAnrechnen.st && <ST />}

					</ul>
					<span className="formLabel">durch prüfungsäquivalente Leistungen ersetzen.</span>
					<br />

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<b>Bei Auswahl "StR" Urkunde Steuerberater oder Prüfungsbescheinigung Steuerberater:</b>
						</div>
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							{pruefungsbescheinigung
								? pruefungsbescheinigung.map((f) => {
									return <div>{f.name}</div>;
								})
								: null}
						</div>
					</div>
					<br />
					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<b>Bei Auswahl "BWL" und/oder "WR" 8a-Masterzeugnis und -urkunde oder 13b-Zeugnis und -Urkunde und Transcript of Records; Bescheinigung über die Prüfungsleistungen; 13b-Zertifikat:</b>
						</div>
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							{masterzeugnis
								? masterzeugnis.map((f) => {
									return <div>{f.name}</div>;
								})
								: null}
						</div>
					</div>

					{/*<span className="formLabel">Hier sind meine Bescheinigungen</span>
					<ul>
						<li>
							<p>Dokumente 1</p>
						</li>
						<li>
							<p>Dokument 2</p>
						</li>
					</ul> */}

				</div>

				<div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
						<input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
					</div>
				</div>
			</>
		);
	}
}

const MenuRight = ({ tab, handleSubmit }) => {
	const dispatch = useDispatch();
	return (
		<div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide" >
			<p className="menu_right_header">Rücknahme der Anmeldung zu einer Modulprüfung</p>
			<ul id="menu_right">
				<li style={tab === 1 ? { background: '#a0bcd1' } : {}}>
					<a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))} style={tab === 1 ? { background: '#a0bcd1' } : {}}>Auswahl Modul(e)</a>
				</li>
				<li style={tab === 2 ? { background: '#a0bcd1' } : {}}>
					<a href="#" onClick={(e) => { handleSubmit(e) ? dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG)) : dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)) }} style={tab === 2 ? { background: '#a0bcd1' } : {}}>Zusammenfassung und Abschicken</a>
				</li>
			</ul>
			<br /><br />
		</div>
	);
}

export const AnrechnenForm = () => {
	const dispatch = useDispatch();
	const tab = useSelector(state => state.rightMenu);
	const ml = new ModulClass();
	const [modul, setModul] = useState(ml);
	const mlb = useSelector(state => state.modulList.modulListBefore);
	const [modulBefore, setModulBefore] = useState(mlb)
	const modulLoading = useSelector((state) => state.modulList.loading);
	const zulassungLoading = useSelector((state) => state.zulassung.loading);
	const [showSpinner, setShowSpinner] = useState(true);
	const z = useSelector(state => state.zulassung.zulassung);
	const [zulassung, setZulassung] = useState(z);
	const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
	const [zulassungsblock, setZulassungsblock] = useState(zb);

	const [pruefungsbescheinigung, setPruefungsbescheinigung] = useState([]);
	const [masterzeugnis, setMasterzeugnis] = useState([]);

	var kannAnrechnen; var aktuelSemester; var semester;

	if (modul && modulBefore) {
		aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);

		if (modulBefore.modulanrechnungen) {
			kannAnrechnen = new ModulAnrechnungenClass();
			kannAnrechnen.wp = modulBefore.modulanrechnungen.wp; // Nicht Anrechnenbar - Ignorieren
			kannAnrechnen.bwl = modulBefore.modulanrechnungen.bwl;
			kannAnrechnen.wr = modulBefore.modulanrechnungen.wr;
			kannAnrechnen.st = modulBefore.modulanrechnungen.st;
		}

		if (aktuelSemester !== "SEM") {
			semester = parseInt(aktuelSemester.split('/')[0], 10);
		} else {
			semester = 0;
		}
	}

	useEffect(() => {
		if (!modulLoading && !zulassungLoading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [modulLoading, zulassungLoading]);

	useEffect(() => {
		setModulBefore(mlb);
	}, [mlb]);

	useEffect(() => {
		setZulassungsblock(zb);
	}, [zb]);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!modul.modulanrechnungen.wp &&
			!modul.modulanrechnungen.wr &&
			!modul.modulanrechnungen.bwl &&
			!modul.modulanrechnungen.st
		) {
			toast.error("Sie können keine weitere Modulprüfung auswählen.")
			return false;
		}

		toast.info("Die Daten wurden zwischengespeichert.");
		dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
		return true;
	};

	const ZusammenfassungText = () => {
		return (
			<div className="hinweisText" style={{ marginBottom: '20px' }}>
				<p>
					Ihre Mitteilung zur Anrechnung von prüfungsäquivalenten Leistungen wird hier zusammengefasst.
					Bitte prüfen Sie Ihre Angaben , bevor Sie Ihre Mitteilung verbindlich absenden.
				</p>
				<p>
					Ihre Mitteilung wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.
				</p>
			</div>
		);
	}

	if (!modul)
		return (
			<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
				<AnrechnenText />

				<ModulNochNichtAnwendbar />
			</div>
		);
	else {
		if (modulBefore && modulBefore.zwischegespeichert === 2) {
			return (
				<>
					<form>
						<Spinner show={showSpinner} />
						<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">

							{tab === 1 && <AnrechnenText />}
							{tab === 2 && <ZusammenfassungText />}


							{tab === 1 && <Anrechnen
								pruefungsbescheinigung={pruefungsbescheinigung}
								setPruefungsbescheinigung={setPruefungsbescheinigung}
								masterzeugnis={masterzeugnis}
								setMasterzeugnis={setMasterzeugnis}
								aktuelSemester={aktuelSemester}
								semester={semester}
								modul={modul}
								setModul={setModul}
								kannAnrechnen={kannAnrechnen}
								modulLoading={modulLoading}
								showSpinner={showSpinner}
								setShowSpinner={setShowSpinner}
								handleSubmit={handleSubmit}
							/>}
							{tab === 2 && <Zusammenfassung
								aktuelSemester={aktuelSemester}
								semester={semester}
								modul={modul}
								modulBefore={modulBefore}
								kannAnrechnen={kannAnrechnen}
								masterzeugnis={masterzeugnis}
								pruefungsbescheinigung={pruefungsbescheinigung}
								showSpinner={showSpinner}
								setShowSpinner={setShowSpinner}
							/>}
						</div>
					</form>
					{tab === 1 && (<MenuRight
						tab={tab}
						handleSubmit={handleSubmit}
					/>)}
					{tab === 2 && (<MenuRight
						tab={tab}
						handleSubmit={handleSubmit}
					/>)}
				</>
			);
		} else {
			return (
				<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
					<AnrechnenText />

					<ModulNochNichtAnwendbar />
				</div>
			);
		}

	}


}